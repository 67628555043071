.footer {
  position: absolute;
  padding-left: 16px;
  margin-bottom: 4px;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 600px) {
    position: relative;
    margin-bottom: 16px;
    flex-direction: column;
    text-align: center;
  }
}
